var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "demoWrap" },
      [
        _c("transition", { attrs: { name: "fade" } }, [
          !_vm.isAuth
            ? _c("div", { staticClass: "dim on" }, [_c("RequestDemo")], 1)
            : _vm._e()
        ]),
        _vm._m(0)
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "demoRight" }, [
      _c("div", { staticClass: "demoVideo" }, [
        _c("h2", [_vm._v("Reviewmind for Business")]),
        _c("video", { attrs: { controls: "" } }, [
          _c("source", {
            attrs: {
              src:
                "https://reviewmindbucket.s3.ap-northeast-2.amazonaws.com/reviewmindDemo.mp4",
              type: "video/mp4"
            }
          })
        ]),
        _c("p", [
          _vm._v(
            " We train NLP-powered AI models to understand your customers! "
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "[리뷰마인드]는 AI 및 NLP 기술 기반의 자동 리뷰 분석/관리/마케팅 서비스를 SaaS 솔루션 형태로 제공하는 벤처 기업입니다. 저희 서비스를 이용하시면 귀사의 제품, 서비스, 및 브랜드에 대한 고객들의 감성, 선호도, 제안 아이디어를 보다 빠르고 편리하며 일관성있고 또 정확하게 확인하고 대응하실 수 있습니다. "
          )
        ]),
        _c("a", { attrs: { href: "tel:02-713-5847" } }, [
          _c("i", { staticClass: "fas fa-phone-alt" }),
          _vm._v(" 02-713-5847 ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }