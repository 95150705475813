var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "demoLeft" }, [
    _c(
      "h1",
      [
        _c("router-link", { attrs: { to: "/" } }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/reviewmind/logo2.png"),
              alt: "로고"
            }
          })
        ])
      ],
      1
    ),
    _c("h2", [_vm._v("리뷰 분석을 쉽게!")]),
    _c("p", [_vm._v("간단한 인증으로 리뷰마인드를 이용해보세요")]),
    _c("div", { staticClass: "inputArea" }, [
      _c("div", { staticClass: "cover bg" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.company,
              expression: "company"
            }
          ],
          attrs: { type: "text", placeholder: "회사명" },
          domProps: { value: _vm.company },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.company = $event.target.value
            }
          }
        })
      ]),
      _c("div", { staticClass: "cover bg" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name"
            }
          ],
          attrs: { type: "text", placeholder: "이름" },
          domProps: { value: _vm.name },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.name = $event.target.value
            }
          }
        })
      ]),
      _c("div", { staticClass: "cover bg" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.email,
              expression: "email"
            }
          ],
          attrs: { type: "text", placeholder: "이메일을 입력해주세요" },
          domProps: { value: _vm.email },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.email = $event.target.value
            }
          }
        })
      ]),
      _c("div", { staticClass: "cover bg" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.mobileNo,
              expression: "mobileNo"
            }
          ],
          attrs: { type: "text", placeholder: "핸드폰번호를 입력해주세요" },
          domProps: { value: _vm.mobileNo },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.mobileNo = $event.target.value
            }
          }
        })
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.verifyCodeVisible,
              expression: "verifyCodeVisible"
            }
          ],
          staticClass: "cover bg"
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.certNumber,
                expression: "certNumber"
              }
            ],
            attrs: {
              type: "text",
              placeholder: " 인증번호 입력를 입력해주세요"
            },
            domProps: { value: _vm.certNumber },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.certNumber = $event.target.value
              }
            }
          }),
          _c("button", { on: { click: _vm.verifyNumber } }, [_vm._v("확인")])
        ]
      ),
      _c("button", { attrs: { type: "button" }, on: { click: _vm.submit } }, [
        _vm._v("Watch Demo")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }