import { render, staticRenderFns } from "./WatchDemo.vue?vue&type=template&id=785d0588&"
import script from "./WatchDemo.vue?vue&type=script&lang=js&"
export * from "./WatchDemo.vue?vue&type=script&lang=js&"
import style0 from "./WatchDemo.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('785d0588')) {
      api.createRecord('785d0588', component.options)
    } else {
      api.reload('785d0588', component.options)
    }
    module.hot.accept("./WatchDemo.vue?vue&type=template&id=785d0588&", function () {
      api.rerender('785d0588', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/WatchDemo.vue"
export default component.exports